/// Applies reset styles for a `<ul>` element
@mixin list-reset {
	list-style: none;
	padding: 0;
	// Reset CSS removes margins from ul and ol
	// margin: 0;
}

/// Applies reset styles for a `<button>` element
@mixin button-reset {
	background: transparent;
	border: none;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
}

/// Applies reset rules for a text-like `<input>` element
@mixin input-reset {
	border: none;
	border-radius: 0;
}
