.header {
	padding-top: 1em;
	padding-bottom: 1rem;

	@include contrast-primary;

	border-bottom: 2px solid;
	@include use-var(border-bottom-color, --accent, currentColor);
}

.header__layout {
	@include layout-wrapper;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@supports (display: grid) {
		display: grid;
		grid-row-gap: 0.5rem;
		grid-template-areas:
			"site-name tools"
			"nav tools";
	}
}

.header__site-name {
	display: block;
	width: 100%;

	grid-area: site-name;

	@include font-heading-20b;
}

.header__nav {
	grid-area: nav;
}

.header__tools {
	grid-area: tools;

	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
}
