html, body {
	padding: 0;
	margin: 0;

	line-height: 1.2;
}

* {
	box-sizing: border-box;
}

ul,
ol,
dd {
	margin: 0;
}

img {
	display: block;
	max-width: 100%;
}

form {
	margin: 0;
}

p:first-child {
	margin-top: 0;
}
p:last-child {
	margin-bottom: 0;
}

fieldset {
	padding: 0;
	margin: 0;
	border: none;
}

button, input, select, textarea {
	font-size: 1rem;
}

a {
	text-decoration: none;
}
