@use "sass:map";

/// A map of breakpoint labels to sizes, for use with `bp-` mixins
$breakpoints: (
	"xs": 420px,
	"s": 768px,
	"m": 1024px,
	"l": 1440px,
	"xl": 1680px,
);

///////////////////////
// Breakpoint Mixins //
///////////////////////

/// Use a media query for width >= the specified size. Expects value in `px` or as a string label e.g. `"m"`
/// @param {string | length} $from - A size label or size to use for the media query
@mixin bp-from($from) {
	$fromVal: map.get($breakpoints, $from);
	@if (not $fromVal) {
		$fromVal: $from;
	}

	@media (min-width: $fromVal) {
		@content;
	}
}

/// Use a media query for widths < the specified size. Expects value in `px` or as a string label e.g. `"m"`
/// @param {string | length} $to - A size label or size to use for the media query
@mixin bp-to($to) {
	$toVal: map.get($breakpoints, $to) - 0.01px;
	@if (not $toVal) {
		$toVal: $to - 0.01px;
	}

	@media (max-width: $toVal) {
		@content;
	}
}

/// Use a media query for widths between the specified sizes. Expects values in `px` or as a string label e.g. `"m"`
/// @param {string | length} $from - A size label or size to use for the lower bound of the media query
/// @param {string | length} $to - A size label or size to use for the upper bound of the media query
@mixin bp-between($from, $to) {
	@include bp-from($from) {
		@include bp-to($to) {
			@content;
		}
	}
}

