@import "scaffolding";

@import "base/reset";
@import "base/palette";
@import "base/fonts";

@import "extendables/card";
@import "extendables/content";

@import "components/header";
@import "components/menu";
@import "components/footer";
@import "components/colour-scheme";

@import "components/skip-link";
@import "components/page";
@import "components/content";
@import "components/notice";
@import "components/toast";

body {
	@include contrast-primary;
}
