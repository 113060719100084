.notice {
	@include contrast-secondary;
	.file & {
		@include contrast-primary;
	}
	display: flex;
	align-items: flex-start;

	padding: 1em;
	border-radius: 0.5rem;
	box-shadow: inset 0 0 0 1px currentColor;

	page-break-inside: avoid;

	+ .notice {
		margin-top: 1rem;
	}
}

.notice__icon {
	margin-right: 1rem;

	.notice--info & {
		@include icon-indicator($icon-info);
	}

	.notice--warning & {
		@include icon-indicator($icon-warning);
	}

	.notice--error & {
		@include icon-indicator($icon-error);
	}
}

.notice__body {
	// Adjust alignment of top line to more closely match icon alignment
	padding-top: 0.4em;
}

.notice__title {
	display: block;
	margin-bottom: 0.5em;

	@include font-heading-20b;
}

.notice__message {
	@include font-base-18;

	@extend %content;
}
