.page {}

.page__head {
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-bottom: 2px solid;
	@include use-var(border-bottom-color, --accent, currentColor);
	margin-bottom: 2rem;

	@include contrast-brand;
}

.page__head__layout {
	@include layout-wrapper;

	display: flex;
	flex-wrap: wrap;

	@supports (display: grid) {
		display: grid;
		@include bp-to("m") {
			grid-template-columns: 1fr;
			grid-template-areas:
				"back"
				"h1"
				"subtitle";
		}
		@include bp-from("m") {
			grid-template-columns: min-content 1fr;
			grid-template-areas:
				"back h1"
				"back subtitle";
		}
	}
}

.page__head__back-link {
	grid-area: back;

	@include button($icon: true, $theme: "secondary--darken");

	margin-right: 1rem;

	@include print-hide;
}

.page__head__back-link__icon {
	@include button__icon($icon-arrow-left);
	&::before {
		font-family: $font-icons;
		content: $icon-arrow-left;
	}
}

.page__title {
	grid-area: h1;
	@include bp-to("m") {
		width: 100%;
	}

	margin: 0 0 0.5rem;
	&:last-child {
		margin-bottom: 0;
	}
	@include font-heading-40b;
}

.page__subtitle {
	width: 100%;
	grid-area: subtitle;

	display: block;
	@include font-base-18;
}

.page__body {}

.page__body__layout {
	@include layout-wrapper;
	padding-bottom: 5rem;
}
