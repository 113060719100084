@use "sass:string";

$unicode-range-latin-ext: string.unquote("U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF");
$unicode-range-latin: string.unquote("U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD");

// Font families
$font-base: "Roboto", arial, sans-serif;
$font-heading: "Montserrat", arial, sans-serif;
$font-icons: "font-icons", #{$font-base};

// Font weights
$regular: 400;
$semibold: 600;
$bold: 700;

// Font icons
// !IMPORTANT: When adding new icons, update ?v=${number} on font sources in `_fonts-icons.scss` and preload tags in `head.ejs`
$icon-copy: "\1f5d0";
$icon-plus: "\2b";
$icon-minus: "\2d";
$icon-search: "\1f50d";
$icon-arrow-up: "\2191";
$icon-arrow-right: "\2192";
$icon-arrow-down: "\2193";
$icon-arrow-left: "\2190";
$icon-brightness: "\1f506";
$icon-warning: "\26a0";
$icon-error: "\1f6d1";
$icon-info: "\1f6c8";
$icon-cross: "\274c";
$icon-tick: "\2714";

/////////////////
// Font Mixins //
/////////////////

// These mixins can be used to apply consistent font variants.
// Naming convention is based on the font's size on desktop devices.
// For example, `font-base-16b` means "base font, 16px at desktop, and bold"

@mixin font-heading-40b {
	font-family: $font-heading;
	font-weight: $bold;
	@include bp-to("s") {
		font-size: rem(32px);
	}
	@include bp-from("s") {
		font-size: rem(40px);
	}
}

@mixin font-heading-32b {
	font-family: $font-heading;
	font-weight: $bold;
	@include bp-to("s") {
		font-size: rem(28px);
	}
	@include bp-from("s") {
		font-size: rem(32px);
	}
}

@mixin font-heading-28b {
	font-family: $font-heading;
	font-weight: $bold;
	@include bp-to("s") {
		font-size: rem(26px);
	}
	@include bp-from("s") {
		font-size: rem(28px);
	}
}

@mixin font-heading-24b {
	font-family: $font-heading;
	font-weight: $bold;
	font-size: rem(24px);
}

@mixin font-heading-22b {
	font-family: $font-heading;
	font-weight: $bold;
	font-size: rem(22px);
}

@mixin font-heading-20b {
	font-family: $font-heading;
	font-weight: $bold;
	font-size: rem(20px);
}

@mixin font-base-24b {
	font-family: $font-base;
	font-weight: $bold;
	font-size: rem(24px);
	line-height: 1.4;
}

@mixin font-base-20 {
	font-family: $font-base;
	font-weight: $regular;
	font-size: rem(20px);
	line-height: 1.4;
}

@mixin font-base-20b {
	font-family: $font-base;
	font-weight: $bold;
	font-size: rem(20px);
	line-height: 1.4;
}

@mixin font-base-18 {
	font-family: $font-base;
	font-weight: $regular;
	font-size: rem(18px);
	line-height: 1.4;
}

@mixin font-base-18b {
	font-family: $font-base;
	font-weight: $bold;
	font-size: rem(18px);
	line-height: 1.4;
}

@mixin font-base-18bu {
	@include font-base-18b;
	text-transform: uppercase;
}

@mixin font-base-16 {
	font-family: $font-base;
	font-weight: $regular;
	font-size: rem(16px);
	line-height: 1.4;
}
