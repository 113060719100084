/*!
Font icons chosen from IcoMoon free icons:
https://icomoon.io/#icons-icomoon

Icons used under CC BY 4.0
https://creativecommons.org/licenses/by/4.0/
*/
@font-face {
	font-family: "font-icons";
	src: url("../fonts/font-icons/font-icons.eot?v=2");
	src:
		url("../fonts/font-icons/font-icons.eot#iefix?v=2") format("embedded-opentype"),
		url("../fonts/font-icons/font-icons.ttf?v=2") format("truetype"),
		url("../fonts/font-icons/font-icons.woff?v=2") format("woff"),
		url("../fonts/font-icons/font-icons.svg?v=2#font-icons") format("svg");
	font-weight: $regular;
	font-style: normal;
	font-display: block;
}

// Also define bold variant so browsers won't try to fake it
@font-face {
	font-family: "font-icons";
	src: url("../fonts/font-icons/font-icons.eot&v=2");
	src:
		url("../fonts/font-icons/font-icons.eot#iefix&v=2") format("embedded-opentype"),
		url("../fonts/font-icons/font-icons.ttf&v=2") format("truetype"),
		url("../fonts/font-icons/font-icons.woff&v=2") format("woff"),
		url("../fonts/font-icons/font-icons.svg&v=2#font-icons") format("svg");
	font-weight: $bold;
	font-style: normal;
	font-display: block;
}
