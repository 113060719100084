.content {
	@extend %content;

	margin: 1rem 0;
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}

	dl {
		&,
		> div {
			margin-bottom: 1em;
			&:last-child {
				margin-bottom: 0;
			}
		}

		dt {
			@include font-base-18b;
		}
	}
}
