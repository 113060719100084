/// Create an indicator icon, with colour determined automatically based on the chosen icon.
/// @param {*} $icon A character to use within the `$font-icons` family.
/// @param {*} $size [rem(22px)] The size of the icon, ideally specified in rem. The indicator itself will be twice this size
@mixin icon-indicator($icon, $size: rem(22px)) {
	font-size: $size;

	width: 2em;
	height: 2em;
	padding: 0.5em;
	line-height: 1;

	border-radius: 100%;
	box-shadow: inset 0 0 0 2px currentColor;
	box-shadow: inset 0 0 0 2px var(--accent);

	&::before {
		font-family: $font-icons;
		content: $icon;
	}

	@if ($icon == $icon-tick) {
		@include contrast-green;
	} @else if ($icon == $icon-info) {
		@include contrast-blue;
	} @else if ($icon == $icon-warning) {
		@include contrast-yellow;
		&::before {
			// Warning icon doesn't look centred, so bump it up a little
			position: relative;
			top: -0.1em;
		}
	} @else if ($icon == $icon-error) {
		@include contrast-red;
	} @else if ($icon == $icon-cross) {
		@include contrast-red;
	}
}
