@use "sass:map";

/// A helper for applying button themes
/// @param {string} $theme The theme to apply.
@mixin button-theme($theme) {
	@if ($theme == "primary") {
		@include contrast-primary;
		@include active {
			@include contrast-primary--active;
		}

		border: 1px solid;
		@include use-var(border-color, --accent, currentColor);

		@include transition("color, background-color");
	} @else if ($theme == "secondary") {
		@include contrast-secondary;
		@include active {
			box-shadow: inset 0 0 0 0.1em currentColor;
			box-shadow: inset 0 0 0 0.1em var(--accent);
		}

		border: 1px solid;
		@include use-var(border-color, --accent, currentColor);

		@include transition(box-shadow);
	} @else if ($theme == "secondary--darken") {
		@include contrast-secondary;
		@include active {
			@include contrast-secondary--active;
		}

		border: 1px solid;
		@include use-var(border-color, --accent, currentColor);

		@include transition("color, background-color");
	} @else {
		@error "Unrecognised button theme option #{$theme}";
	}
}

/// A button
/// @param {string} $size ["m"] The size variant of the button
/// @param {boolean} $icon [false] Whether or not the button contains only an icon
/// @param {string} $theme [null] An optional theme to apply to the button
@mixin button($size: "m", $icon: false, $theme: null) {
	@include button-reset;

	$height: null !default;

	@if ($icon == true) {
		@if ($size == "m") {
			$height: rem(44px);
			font-size: rem(18px);
		} @else {
			@error "Unrecognised button size option #{$size}";
		}

		height: $height;
		width: $height;
		line-height: $height;
	} @else {
		@if ($size == "m") {
			$height: rem(44px);
			@include font-base-18;
		} @else {
			@error "Unrecognised button size option #{$size}";
		}

		height: $height;
		padding: 0 0.8em;
	}

	@if ($theme) {
		@include button-theme($theme);
	}
}

/// The element for an icon in an icon button
/// @param {string} $icon The icon to display
@mixin button__icon($icon: null) {
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	@if ($icon) {
		&::before {
			font-family: $font-icons;
			content: $icon;
		}
	}
}
