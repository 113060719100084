%card {
	@include contrast-secondary;
	@media print {
		box-shadow: none;
	}

	padding: 1rem;
	border: rem(4px) solid;
	@include use-var(border-color, --accent, currentColor);
	border-radius: 0.5rem;

	page-break-inside: avoid;
}
