$colour-scheme-bp: "s";

.colour-scheme {
	display: none;

	@supports (color: var(--test)) {
		display: flex;
		justify-content: flex-end;

		body.no-js & {
			display: none;
		}
		@include print-hide;
	}

	position: relative;
	@include bp-from($colour-scheme-bp) {
		min-width: 13ch;
	}
}

.colour-scheme__face {
	@include bp-to($colour-scheme-bp) {
		@include button($theme: "secondary", $icon: true);
	}
	@include bp-from($colour-scheme-bp) {
		@include button($theme: "secondary");
	}

	&:focus-visible {
		// Bring it in front of the menu to ensure its outline can display on top
		position: relative;
		z-index: 1;
	}

	&[aria-expanded="true"] {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.colour-scheme__face-icon {
	@include bp-to($colour-scheme-bp) {
		@include button__icon;
	}

	&::before {
		font-family: $font-icons;
		content: $icon-brightness;

		@include bp-from($colour-scheme-bp) {
			display: inline-block;
			vertical-align: middle;
			margin-right: 0.2em;
		}
	}
}

.colour-scheme__face-text {
	@include bp-to($colour-scheme-bp) {
		display: none;
	}
	@include bp-from($colour-scheme-bp) {
		display: inline-block;
	}

	vertical-align: middle;
}

.colour-scheme__menu {
	@include list-reset;

	display: none;
	&[aria-expanded="true"] {
		display: block;
	}

	position: absolute;
	top: 100%;
	width: 100%;
	min-width: 13ch;

	@include contrast-secondary;
	border: 1px solid;
	@include use-var(border-color, --accent, currentColor);
	margin-top: -1px;
}

.colour-scheme__menu__option {
	cursor: pointer;
	padding: 0.2em 0.5em;

	@include contrast-secondary;
	@include active {
		@include contrast-secondary--active;
	}
	&:focus-visible {
		// Bring it on top of its positioned subsequent sibling so its focus outline can show in full
		z-index: 1;
	}
	@include transition(background-color);

	@include font-base-16;

	padding-right: calc(0.5em + 3ch);
	position: relative;
	&[aria-checked="true"] {
		&::after {
			font-family: $font-icons;
			content: $icon-tick;
			position: absolute;
			right: 0.5em;
		}
	}
}
